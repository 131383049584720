import React, { useEffect, useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { Button, Popup } from 'semantic-ui-react'
// Components
import { Loading, PageHeader, Seo } from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
import { useCartContext } from '../context/CartContext'
// Styles
import { Container, Wrapper } from '../styles/common'
import {
  AssociateItem,
  AssociateType,
  ContactInfoItem,
  ContactInfoItemImg,
  ContactInfoItemValue,
  ProfileImgContainer,
  ProfileImg,
  UserName,
  StyledIcon,
} from '../styles/Profile.styles'
import infoIcon from '../svg/InfoIcon.svg'
import editIcon from '../svg/Edit Icon.svg'
import { ThemedPopup } from '../components/Verification/Verification.styles'
import BecomeAmbButton from '../components/Buttons/BecomeAmbButton'
import EditDisplayNameModal from '../components/EditDisplayName/EditDisplayNameModal'

const ProfilePage = () => {
  const [showCopied, setShowCopied] = useState(false)
  const [open, setOpen] = useState(false)
  const popupRef = useRef()
  const [openDisplayNamePopup, setOpenDisplayNamePopup] = useState(false)
  const [isEditDisplayNameOpen, setIsEditDisplayNameOpen] = useState(false)
  const { qUser, isAuthenticated, userType } = useAuthContext()
  const {
    prismicData: {
      prismicGeneral,
      prismicProfilePage: {
        communication_type_icon,
        default_image,
        email_icon,
        email,
        loading,
        new_user,
        page_title,
        phone_number_icon,
        profile_header,
        text,
        unavailable,
        username,
        display_name_popup,
      },
    },
  } = usePrismic()
  const { isAmbOfferInCart } = useCartContext()

  useEffect(() => {
    if (!isAuthenticated) navigate('/')
  }, [])

  const profileImgUrl = () => {
    if (qUser?.profileUrl) return qUser.profileUrl

    return default_image.url
  }

  const showCommTypes = () => {
    if (qUser.receiveEmail && qUser.receiveText)
      return `${email[0].text}/${text[0].text}`
    if (qUser.receiveEmail && !qUser.receiveText) return `${email[0].text}`
    return `${text[0].text}`
  }

  const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setShowCopied(true)
      return navigator.clipboard.writeText(str)
    }
    return Promise.reject('The Clipboard API is not available.')
  }

  if (!qUser) return <Loading loading={true} message={loading[0].text} />

  return (
    <>
      <Seo title={page_title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">{profile_header[0].text}</PageHeader>
        <Container align="center" padded>
          <ProfileImgContainer />
          <ProfileImg data-qa="profileImage" src={profileImgUrl()} />
        </Container>

        <Container align="center" padded>
          <UserName data-qa="profileUsername">
            {`${qUser.firstName} ${qUser.lastName}` || new_user[0].text}
          </UserName>
          {qUser?.associateType === 'AMBASSADOR' && qUser.displayName && (
            <Container row justify="space-between">
              <div style={{ flex: 1 }} />
              <Container row justify="center" style={{ flex: 3 }}>
                <UserName data-qa="profileUsername">
                  {qUser.displayName || ''}
                </UserName>
              </Container>
              <div style={{ flex: 1 }}>
                <ThemedPopup
                  content={display_name_popup}
                  on="click"
                  onClose={() => setOpenDisplayNamePopup(false)}
                  onOpen={() => setOpenDisplayNamePopup(true)}
                  open={openDisplayNamePopup}
                  position="bottom right"
                  hideOnScroll={true}
                  trigger={
                    <StyledIcon style={{ marginLeft: 8 }} src={infoIcon} />
                  }
                />
                <StyledIcon
                  onClick={() => setIsEditDisplayNameOpen(true)}
                  src={editIcon}
                  style={{ marginLeft: 8 }}
                />
              </div>
            </Container>
          )}
          <AssociateType>
            <AssociateItem bold data-qa="profileAssociateType">
              {qUser?.associateType}
            </AssociateItem>
            <AssociateItem bold data-qa="profileAssociateID">
              &nbsp;|&nbsp;{qUser?.legacyAssociateId}
            </AssociateItem>
          </AssociateType>

          {qUser?.associateType === 'AMBASSADOR' ? (
            <>
              <AssociateType>
                <AssociateItem slug data-qa="profileAssociateSlug">
                  {`qsciences.com/store/${qUser?.associateSlug}`}
                  <Popup
                    content={prismicGeneral.msg_copied}
                    eventsEnabled={showCopied}
                    on="click"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    hideOnScroll
                    onMount={() => setTimeout(() => setOpen(false), 2000)}
                    open={open}
                    trigger={
                      <Button
                        className="copy-button"
                        basic
                        compact
                        icon="clipboard"
                        ref={popupRef}
                        onClick={() =>
                          copyToClipboard(
                            `qsciences.com/store/${qUser?.associateSlug}`
                          )
                        }
                      />
                    }
                  />
                </AssociateItem>
              </AssociateType>
            </>
          ) : null}
        </Container>
        <Container align="center" padding="2em 1em 1em 1em">
          <ContactInfoItem>
            <ContactInfoItemImg src={phone_number_icon.url} />
            <ContactInfoItemValue data-qa="profilePhoneNumber">
              {qUser.primaryPhoneNumber || unavailable[0].text}
            </ContactInfoItemValue>
          </ContactInfoItem>
          <ContactInfoItem>
            <ContactInfoItemImg src={email_icon.url} />
            <ContactInfoItemValue data-qa="profileEmail">
              {qUser?.uniqueEmailAddress ?? unavailable[0].text}
            </ContactInfoItemValue>
          </ContactInfoItem>
          <ContactInfoItem>
            <ContactInfoItemImg src={communication_type_icon.url} />
            <ContactInfoItemValue data-qa="profileCommunication">
              {showCommTypes()}
            </ContactInfoItemValue>
          </ContactInfoItem>
          {userType !== 'AMBASSADOR' && !isAmbOfferInCart && (
            <ContactInfoItem>
              <BecomeAmbButton $insidemenu={false} />
            </ContactInfoItem>
          )}
        </Container>
      </Wrapper>
      {isEditDisplayNameOpen && (
        <EditDisplayNameModal
          open={isEditDisplayNameOpen}
          onClose={() => setIsEditDisplayNameOpen(false)}
          displayName={qUser?.displayName}
        />
      )}
    </>
  )
}

export default ProfilePage
